// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
import MuiLink from "@material-ui/core/Link"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const EvinrudeOutboardMotorsPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      evinrudeEtec01: file(relativePath: { eq: "evinrude-etec-01.jpg" }) {
        ...BsmFluidImage
      }
      evinrudeEtec02: file(relativePath: { eq: "evinrude-etec-02.jpg" }) {
        ...BsmFluidImage
      }
      brpPartsCatalog: file(relativePath: { eq: "brp-parts-catalog.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Evinrude Outboard Motors">
      <PageTitle title="EVINRUDE OUTBOARD MOTORS" />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            For years, two-stroke engines ruled the roost. They had raw muscle
            but lacked refinement. As boaters demanded cleaner, quieter engines,
            four-strokes pulled ahead. Then came{" "}
            <MuiLink href="http://www.evinrude.com/">Evinrude</MuiLink> E-TEC.
          </Typography>
          <Typography paragraph>
            A two-stroke with the best qualities of a four-stroke, E-TEC is a
            category unto itself. Speed. Power. Weight. Noise. Emissions. In
            every way that matters, Evinrude E-TEC beats the competition.
            Especially when you consider ease-of-use. All our engines are built
            to go 3 years or 300 hours without dealer-scheduled maintenance.
            There’s no break-in period. No oil changes. No belts to replace.
            Ever.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.evinrudeEtec01.childImageSharp.fluid}
            caption="Evinrude Outboard Motor"
            maxWidth={200}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.evinrudeEtec02.childImageSharp.fluid}
            caption="Evinrude Outboard Motor"
            maxWidth={200}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Bay Sails Marine is a platinum authorized Evinrude and Johnson
            Dealership. We carry the full line of Evinrude and Johnson outboard
            motors, available in both two-stroke and four-stroke models and a
            wide range of HP. We have the outboard that will keep you going on
            the water. Please visit <Link to="/directions">our store</Link> to
            see our selection or <Link to="/contact">contact us</Link> for more
            information on a new or used Evinrude and Johnson outboard motor.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            The{" "}
            <MuiLink href="http://epc.brp.com/default.aspx?brands=ej&lang=E&dealerlocator=no">
              Bombardier Electronic Parts Catalog
            </MuiLink>
            . This will let you see diagrams of your Evinrude or Johnson motor.
            You’ll be able to find the exact part number you need and print a
            pick ticket that you can bring to us.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.brpPartsCatalog.childImageSharp.fluid}
            caption="BRP Parts Catalog"
            maxWidth={200}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default EvinrudeOutboardMotorsPage
